import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_menu_item = _resolveComponent("el-menu-item");
  var _component_el_menu = _resolveComponent("el-menu");
  var _component_el_aside = _resolveComponent("el-aside");
  return _openBlock(), _createBlock(_component_el_aside, {
    width: "10px"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_menu, {
        "active-color": "#ffd04b",
        "background-color": "rgb(51,65,87)",
        "class": "el-menu-vertical-demo",
        "default-active": _ctx.active,
        "text-color": "#fff",
        router: ""
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, function (v) {
            return _openBlock(), _createBlock(_component_el_menu_item, {
              index: v.path,
              key: v.path
            }, {
              "default": _withCtx(function () {
                return [_createElementVNode("span", null, _toDisplayString(v.meta.title), 1)];
              }),
              _: 2
            }, 1032, ["index"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["default-active"])];
    }),
    _: 1
  });
}