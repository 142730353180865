import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "SideBar",
  setup: function setup() {
    var router = useRouter();
    var route = useRoute();
    var list = router.getRoutes().filter(function (v) {
      return v.meta.isShow;
    });
    return {
      list: list,
      active: route.path
    };
  }
});