import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../layout/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: 'home',
    component: Home,
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          isShow: true,
          title: '首页'
        },
        component: () => import('../views/index.vue')
      },
      // {
      //   path: 'd3',
      //   name: 'd3',
      //   meta: {
      //     isShow: true,
      //     title: 'D3'
      //   },
      //   component: () => import('../views/D3View.vue')
      // },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
 
    next()
  
})

export default router
