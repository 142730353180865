import "core-js/modules/es.array.push.js";
import router from "@/router";
import { defineComponent } from "vue";
import MainContent from "./MainContent.vue";
import SideBar from "./SideBar.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    MainContent: MainContent,
    SideBar: SideBar
  },
  setup: function setup() {
    var exit = function exit() {
      localStorage.removeItem("token");
      router.push("./login");
    };
    return {
      exit: exit
    };
  }
});